<template>
    <div id="note-templates-layout">
        <div class="row">
            <div class="col-lg-12 col-xl-12">

                <b-card>
                  <b-button variant="primary" class="float-right" @click="createNote">Προσθήκη νέου</b-button>
                    <b-card-title>Πρότυπα σημειώσεων</b-card-title>
                    <b-card-sub-title>Δείτε & επεξεργαστείτε τα πρότυπα σημειώσεών σας</b-card-sub-title>
                  <b-input v-model="search" placeholder="Αναζήτηση προτύπου..." class="mb-3 mt-3" style="max-width: 500px" @input="searchByText"/>
                    <div v-if="notesArray.length > 0" class="col-md-12 mt-3">
                        <b-card  v-for="note in notesArray" :key="note.id" class="shadow-sm">
                            <b-card-text>

                                <div class="row">
                                    <div class="col-md-6">
                                        <span class="small-size">Όνομα προτύπου:</span> {{note.name}}
                                    </div>
                                    <div class="col-md-2">
                                        <span class="small-size">Ενέργειες</span>
                                        <b-button  variant="warning" size="xs"
                                                   @click="$router.push('/diet-plans/notes/'+note.id)" > Επεξεργασία</b-button>
                                        <b-button variant="danger" size="xs" class="ml-1" @click="deleteNote(note.id)"><i class="icon-trash"></i></b-button>
                                    </div>

                                </div>

                            </b-card-text>
                        </b-card>
                      <div class="load-more-btn">
                        <b-button v-if="notesArray.length < notes.length && search === ''" variant='primary' size="sm" @click="loadMore">Φόρτωση περισσότερών...</b-button>
                      </div>
                    </div>

                </b-card>


            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default{
    page: {
      title: 'Σημειώσεις',
    },

    data() {
        return {
            user: this.$store ? this.$store.state.auth.currentUser : {} || {},
            notesArray: [],
            page: 1,
            limit: 5,
            search: ''
        }
    },
    computed:{
      ...mapState({
        notes: state => state.notes.notes
      })
    },
      mounted(){
        this.initializeNotesList()
      },

    methods:{
        initializeNotesList(){
          this.page = 1;
          this.notesArray = this.notes.slice((this.page-1)*this.limit, (this.page)*this.limit);
        },
        loadNotesList(){
          this.notesArray.push(...this.notes.slice((this.page-1)*this.limit, (this.page)*this.limit));
        },
        loadMore(){
          this.page++;
          this.loadNotesList();
        },

        searchByText(){
          if(this.search === '') return this.initializeNotesList();
          this.page = 1;
          this.notesArray = this.notes.filter(x => x.name.toLowerCase().includes(this.search.toLowerCase()));
        },

        createNote(){
          this.$router.push({name: 'edit-diet-plan-notes', params: {noteId: 'temp'}});
        },

        deleteNote(noteId){
            let message = {
                title: 'Θέλετε σίγουρα να διαγράψετε το πρότυπο;',
                body: 'Προσοχή. Δεν υπάρχει δυνατότητα επαναφοράς άπαξ και ολοκληρωθεί η διαγραφή.'
            };
            this.$dialog.confirm(message, {
                loader: true,
                okText: 'Διαγραφή',
                cancelText: 'Ακύρωση',
            }).then((dialog) => {

                    this.$store.dispatch('notes/removeNote', noteId ).then((res)=>{
                        dialog.close();
                        this.initializeNotesList();
                    }).catch(() => {
                        this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η διαγραφή απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
                        dialog.close();
                    });

            }).catch(()=>{});
        },
    }
}
</script>
